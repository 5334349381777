import React, { Component } from 'react';

import * as styles from './styles.module.scss';

/* Images */
import portrait from './images/atmosphere_portrait.png';

import atmoOne from './images/atmo1.png';
import atmoTwo from './images/atmo2.png';
import atmoThree from './images/atmo3.png';
import atmoFour from './images/atmo4.png';

class AtmosphereDesign extends Component {
  render() {
    return (
      <section className={styles.atmosphereContainer}>
        <h1>ATMOSPHERE DESIGN</h1>
        <div className={styles.atmosphereContent}>
          <div className={styles.imageContent}>
            <img src={portrait} alt=""/>
          </div>
          <div className={styles.textContent}>
            <p>
              When she styles beautiful spaces, Zaru begins by examining what the client wants people to feel and
              experience. From there, she selects elements that reflect the client’s brand vision and philosophy.
              Those elements include colors, textures and shapes of interior accessories, as well as the furniture
              and artwork necessary to achieve the desired atmosphere. Zaru uses old Eastern philosophies as a key
              element to create harmonious atmospheres where spirituality meets design, which she calls a
              conscious design.
            </p>
            <a href="mailto:hi@yuliazaru.com?Subject=Consulting">Send your message</a>
          </div>
        </div>
        <div className={styles.consultingGallery}>
          <div className={styles.galleryRow}>
            <img src={atmoOne} alt=""/>
            <img src={atmoTwo} alt=""/>
          </div>
          <div className={styles.galleryRow}>
            <img src={atmoThree} alt=""/>
            <img src={atmoFour} alt=""/>
          </div>
        </div>
      </section>
    );
  }
}

export default AtmosphereDesign;
