import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AtmosphereDesign from "../components/AtmosphereDesign/AtmosphereDesign"

const AtmosphereDesignPage = () => (
  <Layout>
    <Seo title="Fine Art" />
    <AtmosphereDesign />
  </Layout>
)

export default AtmosphereDesignPage
